import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/antd.min.css";
// import SpeedLogger from "./features/join_call/SpeedTest";

ReactDOM.render(
    <>
        {/* <SpeedLogger/> */}
        <App />
    </>
    , document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
