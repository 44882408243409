import Styles from "../styles/grid";
import { FaHandPaper } from "react-icons/fa";
import brbicon from "../../../../assets/brb.png";
import dndicon from "../../../../assets/dnd.png";
import { useSelector, useDispatch } from "react-redux";
import { roomSlice } from "../../store/slice";
import { statusColors } from "../../../../app/helper";
import { useContext } from "react";
import { BaseContext } from "../../../../app/BaseConext";
import { IoCopyOutline } from "react-icons/io5";
import { TbScreenShare } from "react-icons/tb";
import { message } from "antd";
import { copyURL } from "../../../../domain/modules/CopyMeetingUrl";

function SidebarTools({ onClickSidebarClose }) {
    const { MyStreams, SocketConnection } = useContext(BaseContext);
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const roomId = useSelector((state) => state.generalSlice.roomId);

    const {
        SidebarInfoBody,
        SidebarInfoBodyHeader,
        SidebarInfoBodyButton,
    } = Styles;


    const handRaisedIds = useSelector(
        ({ generalSlice }) => generalSlice.handRaisedIds
    );
    const roomStatuses = useSelector(
        ({ generalSlice }) => generalSlice.roomStatuses
    );
    const myId = useSelector(
        ({ generalSlice }) => generalSlice.userId
    );
    const isScreenShare = useSelector(
        ({ roomSlice: { uiState } }) => uiState.isScreenShare
      );
      const users = useSelector(({ roomSlice: { users } }) => users);
      const screenShareAllow = useSelector(
        ({ roomSlice: { uiState } }) => uiState.screenShareAllow
      );

    const {
        Sidebar,
        SidebarHeading,
        SidebarHeader,
        SidebarHeaderButton,
        SidebarHR,
        SidebarMemberBody
    } = Styles;

    const rowStyle = (status) => ({
        display: "flex",
        gap: "20px",
        marginLeft: "10px",
        marginRight: "10px",
        marginBottom: "10px",
        padding: "8px",
        borderRadius: "10px",
        alignItems: "center",
        background: "rgb(112, 112, 112)",
        border: selectedStatus === status ? `2px solid ${statusColors[status]}` : "2px solid transparent",
        cursor: "pointer"
    });

    const selectedStatus = useSelector(({ roomSlice }) => {
        return roomSlice.uiState.selectedStatus;
    });

    const handleScreenShare = async () => {
        if (!screenShareAllow) return alert("Someone already shared screen");
        if (isScreenShare) {
            MyStreams.deleteScreenShare().then(() => {
                SocketConnection.screenShareOff();
            });
        } else {
            MyStreams.getScreenShare().then(async ({ screenShare }) => {
                screenShare.getVideoTracks()[0].addEventListener("ended", () => {
                    MyStreams.deleteScreenShare().then(() => {
                        SocketConnection.screenShareOff();
                    });
                })
                SocketConnection.screenShareOn({ screenShare, users });
            }).catch(error => {
                console.log(error.name)
                // if (error.name !== 'NotAllowedError') {
                messageApi.open({
                    type: 'error',
                    content: error.message,
                    duration: 3,
                });
                // }
            });
        }
    };
    const handleStatusClick = async (status) => {
        if (selectedStatus === status) {
            if (status === 'raiseHand') {
                SocketConnection.NotifyRaiseHand(false, handRaisedIds, myId)
            }
            SocketConnection.NotifyRoomStatus(null, roomStatuses, myId)
            return;
        }
        else {
            if (status === 'raiseHand') {
                SocketConnection.NotifyRaiseHand(true, handRaisedIds, myId)
            }
            else {
                SocketConnection.NotifyRaiseHand(false, handRaisedIds, myId)
            }
            SocketConnection.NotifyRoomStatus(status, roomStatuses, myId)
            return;
        }
    };

    return (
        
        <Sidebar>
            {contextHolder}
            <SidebarHeader>
                <SidebarHeading> Tools </SidebarHeading>
                <SidebarHeaderButton onClick={onClickSidebarClose}>X</SidebarHeaderButton>
            </SidebarHeader>
            {/* <SidebarInfoBody>
                <h3 style={{ color: "white" }}>Meeting-Info</h3>
                <h4 style={{ color: "white", fontWeight: "300", whiteSpace: "wrap", textOverflow: "ellipsis", width: "100%", overflow: "hidden" }}>{roomId}</h4>

                <SidebarInfoBodyButton onClick={() => copyURL(roomId, messageApi)}>
                    <IoCopyOutline />
                    Copy Link
                </SidebarInfoBodyButton>
            </SidebarInfoBody> */}
            {/* <SidebarHR style={{ marginTop: "20px" }} /> */}
            <SidebarInfoBody>
                <h3 style={{ color: "white" }}>Screen Share</h3>
                <SidebarInfoBodyButton onClick={handleScreenShare}>
                    <TbScreenShare size={20} style={{ color: "white" }} />
                    share screen
                </SidebarInfoBodyButton>
            </SidebarInfoBody>
            <SidebarHR style={{ marginTop: "20px" }} />
            <SidebarMemberBody style={{ marginBottom: "0%" }} >
                <h3 style={{ color: "white", marginLeft: "10px" }}>Room Statuses</h3>
                <div
                    style={rowStyle("raiseHand")}
                    onClick={() => handleStatusClick("raiseHand")}
                >
                    <FaHandPaper color="#ffff3a" size={18} />
                    <div style={{ color: "white", fontSize: "14px" }}>Raise Hand</div>
                </div>
                <div
                    style={rowStyle("brb")}
                    onClick={() => handleStatusClick("brb")}
                >
                    <img src={brbicon} alt="icon" style={{ width: 25, height: 20 }} />
                    <div style={{ color: "white", fontSize: "14px" }}>Be Right Back</div>
                </div>
                <div
                    style={rowStyle("dnd")}
                    onClick={() => handleStatusClick("dnd")}
                >
                    <img src={dndicon} alt="icon" style={{ width: 22, height: 20 }} />
                    <div style={{ color: "white", fontSize: "14px" }}>Do Not Disturb</div>
                </div>
            </SidebarMemberBody>
            <SidebarHR style={{ marginTop: "20px" }} />
        </Sidebar>
    );
}

export default SidebarTools;
