import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { calculateTimeDifferenceInSeconds } from './CalculateTimeDiference';
import { AddCallingTranscribe } from '../../data/api/Api';
import { useLocation } from 'react-router-dom';

const SpeechToText = ({ SocketConnection }) => {

    const location = useLocation();

    const isTranscribe = useSelector(({ roomSlice }) => {
        return roomSlice.uiState.isTranscribe;
    });

    const { userId, email, userName, roomId, joinDateTime } = useSelector(
        ({ generalSlice }) => generalSlice
    );
    const audioOn = useSelector(
        ({ roomSlice: { users }, generalSlice }) =>
            users[generalSlice?.userId]?.uiState?.audioOn
    );
    const recognitionRef = useRef(null);
    const isRecognitionStartedRef = useRef(false);

    useEffect(() => {
        window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        recognitionRef.current = new window.SpeechRecognition();
        recognitionRef.current.interimResults = true;

        if (audioOn && isTranscribe) {
            recognitionRef.current.addEventListener("result", (e) => {
                const text = Array.from(e.results)
                    .map((result) => result[0])
                    .map((result) => result.transcript)
                    .join("");
                if (e.results[0].isFinal) {
                    const currentTime = new Date().getTime();
                    const data = {
                        'roomId': roomId,
                        'memberId': userId,
                        'memberEmail': email,
                        'memberName': userName,
                        'message': text,
                        'timingMeeting': calculateTimeDifferenceInSeconds(currentTime, joinDateTime),
                        'dateTime': new Date(currentTime).toISOString()
                    }
                    // console.log(data, "==1==", isTranscribe);
                    if (isTranscribe) {
                        // console.log(userId, email, userName, roomId, joinDateTime );
                        // console.log("LOCATION:", location.pathname);
                        // console.log("TRANSCFRIBE SidebarTranscribe: ", isTranscribe);
                        AddCallingTranscribe([data])
                        SocketConnection.Events({ type: 'SEND_TRANSCRIBE', payload: data });
                    }
                }
            });

            recognitionRef.current.onerror = function (event) {
                if (event.error === 'no-speech') {
                    console.log('No speech detected. Please try again.');
                    // Optionally, you can restart the recognition
                    restartRecognition();
                } else {
                    restartRecognition();
                    console.error('Speech recognition error detected:', event.error);
                }
            };

            recognitionRef.current.addEventListener("end", () => {
                // if (!isRecognitionStartedRef.current) {
                //     return;
                // }

                // Restart recognition if it was not manually stopped
                restartRecognition();
            });

            startRecognition();
        }
        // Clean up the recognition when the component unmounts
        return () => {
            stopRecognition();
        };
    }, [isTranscribe, audioOn]);

    const startRecognition = () => {
        if (!isRecognitionStartedRef.current) {
            recognitionRef.current.start();
            isRecognitionStartedRef.current = true;
        }
    };
    const stopRecognition = () => {
        // if (isRecognitionStartedRef.current) {
        recognitionRef.current.stop();
        // }
        isRecognitionStartedRef.current = false;
    };

    const restartRecognition = () => {
        stopRecognition();
        startRecognition();
    };

    return <></>;
};

export default SpeechToText;
