
import Styles from "../styles/grid";
import Styles2 from "../styles/Call.styles";
import MenuSVG from "../../../../assets/Menu.svg";
import LayoutSVG from "../../../../assets/Layout.svg";
import Group2 from "../../../../assets/Group2.svg";
import Path1 from "../../../../assets/Path1.svg";
import Path2 from "../../../../assets/Path2.svg";
import Transcibe from "../../../../assets/transcribe.svg";
import { Badge, Tooltip, message } from 'antd';
import {
    MenuOutlined
} from '@ant-design/icons';
import { useSelector } from "react-redux";
import { useContext } from "react";
import { BaseContext } from "../../../../app/BaseConext";
import { checkUserRaiseHand, statusColors } from "../../../../app/helper";
import {
    TbScreenShare
} from "react-icons/tb";
import { IoCopyOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { copyURL } from "../../../../domain/modules/CopyMeetingUrl";
import { BsMenuButtonWide } from "react-icons/bs";

function BottomRightComponent(props) {
    const { MyStreams, SocketConnection } = useContext(BaseContext)
    const { CallOptionButton, ButtonView, Text, Space } = Styles2;
    const { BottomRightDiv, BottomRightMenuButton, Sidebar, SidebarView } = Styles;
    const { openSidebar, openMobileSidebar } = props;

    const userIds = useSelector((state) => state.roomSlice.userIds);
    const pinCount = useSelector(
        ({ roomSlice: { uiState } }) => uiState.pinCount
    );

    const chatListLength = useSelector(({ roomSlice }) => {
        return roomSlice.uiState.chatList.length;
    });
    const tempNotificationLength = useSelector(({ roomSlice }) => {
        return roomSlice.uiState.tempNotificationLength;
    });
    const isChatBarClosed = useSelector(({ roomSlice }) => {
        return roomSlice.uiState.isChatBarClosed;
    });
    const isHandRised = useSelector(({ roomSlice }) => {
        return roomSlice.uiState.isHandRised;
    });
    const myId = useSelector(
        ({ generalSlice }) => generalSlice.userId
    );
    const handRaisedIds = useSelector(
        ({ generalSlice }) => generalSlice.handRaisedIds
    );
    const adminIds = useSelector(
        ({ generalSlice }) => generalSlice.adminIds
    );
    const handleHandRaised = async () => {
        await SocketConnection.NotifyRaiseHand(!isHandRised, handRaisedIds, myId)
        // console.log(myId, "================================other",isHandRised)
    }
    const isScreenShare = useSelector(
        ({ roomSlice: { uiState } }) => uiState.isScreenShare
    );
    const users = useSelector(({ roomSlice: { users } }) => users);
    const screenShareAllow = useSelector(
        ({ roomSlice: { uiState } }) => uiState.screenShareAllow
    );
    const selectedStatus = useSelector(({ roomSlice }) => {
        return roomSlice.uiState.selectedStatus;
    });

    const approvalMembers = useSelector(({ roomSlice }) => {
        return roomSlice.uiState.approvalMembers;
    });

    // const handleScreenShare = async () => {
    //     if (!screenShareAllow) return alert("Someone already shared screen");
    //     if (isScreenShare) {
    //         MyStreams.deleteScreenShare().then(() => {
    //             SocketConnection.screenShareOff();
    //         });
    //     } else {
    //         MyStreams.getScreenShare().then(async ({ screenShare }) => {
    //             screenShare.getVideoTracks()[0].addEventListener("ended", () => {
    //                 MyStreams.deleteScreenShare().then(() => {
    //                     SocketConnection.screenShareOff();
    //                 });
    //             })
    //             SocketConnection.screenShareOn({ screenShare, users });
    //         }).catch(error => {
    //             console.log(error.name)
    //             // if (error.name !== 'NotAllowedError') {
    //             messageApi.open({
    //                 type: 'error',
    //                 content: error.message,
    //                 duration: 3,
    //             });
    //             // }
    //         });
    //     }
    // };
    const [messageApi, contextHolder] = message.useMessage();
    const search = useLocation().search;
    const roomId = new URLSearchParams(search).get("room");
    // console.log(adminIds,"====1=====");
    // console.log(adminIds.includes(myId),"=====2====");
    return (
        <>
            {contextHolder}
            <BottomRightDiv>
                <Tooltip placement="topLeft" title={"Tools"}>
                    <CallOptionButton>
                        <Badge
                            dot={selectedStatus}
                            offset={[-5, 5]}
                            color={statusColors[selectedStatus]}
                            size="small"
                        >
                            <ButtonView style={{ background: "#707070" }} onClick={() => { openSidebar("sidebartools") }}>
                                <BsMenuButtonWide style={{ color: "white" }} />
                            </ButtonView>
                        </Badge>
                    </CallOptionButton>
                </Tooltip>
                {/* <Tooltip placement="topLeft" title={"Raise hand"}>
                    <CallOptionButton>
                        <ButtonView style={{ background: "#707070" }} onClick={handleHandRaised}>
                            {checkUserRaiseHand(handRaisedIds, myId, "other")}
                        </ButtonView>
                    </CallOptionButton>
                </Tooltip> */}
                {
                    (userIds.length > 1 && pinCount) ? (
                        <Tooltip placement="topLeft" title={"Unpinned Users"}>
                            <CallOptionButton>
                                <ButtonView style={{ background: "#707070" }} onClick={() => { openSidebar("sidebarlayout") }}>
                                    <img src={LayoutSVG} />
                                </ButtonView>
                            </CallOptionButton>
                        </Tooltip>
                    ) : <></>
                }
                <Tooltip placement="topLeft" title={"Copy link"}>
                    <CallOptionButton>
                        <ButtonView
                            style={{ background: "#707070" }}
                            onClick={() => copyURL(roomId, messageApi)}
                        >
                            <IoCopyOutline size={16} />
                        </ButtonView>
                    </CallOptionButton>
                </Tooltip>
                <Tooltip placement="topLeft" title={"Participants"}>
                    <CallOptionButton>
                        {
                            adminIds.includes(myId) ?
                                <Badge
                                    count={approvalMembers.length}
                                    size="small"
                                    style={{ background: "rgb(26, 86, 105)", right: "5px" }}
                                >
                                    <ButtonView style={{ background: "#707070" }} onClick={() => { openSidebar("sidebarmembers") }}>
                                        <img src={Path1} />
                                    </ButtonView>
                                </Badge> :
                                <ButtonView style={{ background: "#707070" }} onClick={() => { openSidebar("sidebarmembers") }}>
                                    <img src={Path1} />
                                </ButtonView>

                        }
                    </CallOptionButton>
                </Tooltip>
                <Tooltip placement="topLeft" title={"Transcribe"}>
                    <CallOptionButton>
                        <Badge
                            count={isChatBarClosed ? chatListLength - tempNotificationLength : 0}
                            size="small"
                        >
                            <ButtonView style={{ background: "#707070" }} onClick={() => { openSidebar("sidebartranscribe") }}>
                                <img src={Transcibe} />
                            </ButtonView>
                        </Badge>
                    </CallOptionButton>
                </Tooltip>
                <Tooltip placement="topLeft" title={"Chat"}>
                    <CallOptionButton>
                        <Badge
                            count={isChatBarClosed ? chatListLength - tempNotificationLength : 0}
                            size="small"
                        //  style={{ background: "rgb(26, 86, 105)", right: "5px" }}
                        >
                            <ButtonView style={{ background: "#707070" }} onClick={() => { openSidebar("sidebarchat") }}>
                                <img src={Path2} />
                            </ButtonView>
                        </Badge>
                    </CallOptionButton>
                </Tooltip>
                {/* <Tooltip placement="topLeft" title={"Screen share"}>
                    <CallOptionButton>
                        <ButtonView
                            style={{ background: "#707070" }}
                            onClick={handleScreenShare}
                        >
                            <TbScreenShare size={18} color={isScreenShare ? "yellow" : ""} />
                        </ButtonView>
                    </CallOptionButton>
                </Tooltip> */}

            </BottomRightDiv>
            <BottomRightMenuButton>
                <CallOptionButton>
                    <ButtonView style={{ background: "#707070" }} onClick={() => { openMobileSidebar() }}>
                        <img src={MenuSVG} style={{ height: "15px" }} />
                    </ButtonView>
                </CallOptionButton>
            </BottomRightMenuButton>
        </>

    );
}

export default BottomRightComponent;