
import Styles from "../styles/grid";
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Switch, message } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { roomSlice } from "../../store/slice";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { AddCallingTranscribe } from "../../../../data/api/Api";
import { calculateTimeDifferenceInSeconds } from "../../../../domain/modules/CalculateTimeDiference";


function SidebarTranscribe({ onClickSidebarClose, sidebar, SocketConnection }) {
    const dispatch = useDispatch();
    const transcribeList = useSelector(({ roomSlice }) => {
        return roomSlice.uiState.transcribeList;
    });
    // console.log("-----",transcribeList,"-----");

    const isTranscribe = useSelector(({ roomSlice }) => {
        return roomSlice.uiState.isTranscribe;
    });
    const isGalaxyFolded = useMediaQuery({ maxWidth: 290 });

    const scrollRef = useRef(null);
    const {
        Sidebar,
        SidebarHeading,
        SidebarHeader,
        SidebarHeaderButton,
        SidebarHR,
        SidebarChatAvatarContainer,
        SidebarMemberBody,
        SidebarChatRow,
        Chat
    } = Styles;

    const myId = useSelector(
        ({ generalSlice }) => generalSlice.userId
    );
    // console.log(myId);
    const audioOn = useSelector(
        ({ roomSlice: { users }, generalSlice }) =>
            users[generalSlice?.userId]?.uiState?.audioOn
    );
    const adminIds = useSelector(
        ({ generalSlice }) => generalSlice.adminIds
    );
    const { userId, email, userName, roomId, joinDateTime } = useSelector(
        ({ generalSlice }) => generalSlice
    );

    useEffect(() => {
        // console.log(transcribeList,"=====",sidebar);
        if (sidebar) {
            scrollRef?.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [transcribeList, sidebar])

    const [switchDisabled, setSwitchDisabled] = useState(false);

    const handleLockSwitch = () => {

        // Disable the switch for 3 seconds
        const currentTime = new Date().getTime();
        let payload = {}
        let data = {}
        if (!isTranscribe) {

            const messageContent = 'Admin started transcription'
            payload = {
                message: {
                    type: 'success',
                    content: messageContent,
                    duration: 2,
                },
                roomId
            }
            data = {
                'roomId': roomId,
                'memberId': userId,
                'memberEmail': email,
                'memberName': userName,
                'message': messageContent,
                'timingMeeting': calculateTimeDifferenceInSeconds(currentTime, joinDateTime),
                'dateTime': new Date(currentTime).toISOString()
            }
        } else {
            const messageContent = 'Admin stopped transcription'
            payload = {
                message: {
                    type: 'error',
                    content: messageContent,
                    duration: 2,
                },
                roomId
            }
            data = {
                'roomId': roomId,
                'memberId': userId,
                'memberEmail': email,
                'memberName': userName,
                'message': messageContent,
                'timingMeeting': calculateTimeDifferenceInSeconds(currentTime, joinDateTime),
                'dateTime': new Date(currentTime).toISOString()
            }
        }
        
        if (isTranscribe) {
            console.log("TRANSCFRIBE SidebarTranscribe: ", isTranscribe);
            AddCallingTranscribe([data]);
        }

        setSwitchDisabled(true);
        setTimeout(() => {
            dispatch(roomSlice.actions.updateIsTranscribe(!isTranscribe));
            SocketConnection.Events({ type: 'SEND_TRANSCRIBE', payload: data });
            SocketConnection.Events({ type: 'NOTIFY_ROOM', payload });
            setSwitchDisabled(false);
        }, 2000);

    };

    const formatDateTime = (dateTime) => {
        const originalDateTime = new Date(dateTime);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
        return originalDateTime.toLocaleString('en-US', options);
    }
    return (
        <Sidebar>
            <SidebarHeader>
                <div>
                    <SidebarHeading> Live Transcribe </SidebarHeading>
                    {
                        // adminIds?.includes(myId) &&
                        <Switch
                            style={isTranscribe ? { backgroundColor: "#1A5669" } : { color: "white" }}
                            size={isGalaxyFolded ? "small" : "default"}
                            checkedChildren="On"
                            unCheckedChildren="Off"
                            checked={isTranscribe && audioOn}
                            onChange={handleLockSwitch}
                            disabled={!audioOn || switchDisabled} />
                    }

                </div>
                <SidebarHeaderButton onClick={() => { onClickSidebarClose() }}>X</SidebarHeaderButton>
            </SidebarHeader>
            <SidebarHR style={{ marginTop: "0px" }} />
            <SidebarMemberBody style={{ marginBottom: "8%" }} >
                {
                    transcribeList.map((msgData, Index) => {
                        let hide = false;
                        return (
                            <SidebarChatRow key={`${msgData.memberId}-${Index}`} type={msgData.memberId === myId ? "mine" : ''}  >
                                <div style={{ display: "flex", flexDirection: "column", rowGap: "5px" }}>
                                    {
                                        !hide &&
                                        <div style={{
                                            display: "flex",
                                            flexDirection: msgData.memberId !== myId ? "row" : 'row-reverse',
                                            columnGap: "5px"
                                        }}>
                                            <div style={{
                                                color: "white",
                                                fontSize: "12px",
                                                textAlign: "end"
                                            }}>
                                                {msgData.memberId === myId ? "You" : msgData.memberName}
                                            </div>
                                            <div style={{
                                                color: "white",
                                                fontSize: "10px",
                                                textAlign: "end",
                                                display: "flex",
                                                alignItems: "center"
                                            }}>
                                                {formatDateTime(msgData.dateTime)}
                                            </div>
                                        </div>
                                    }
                                    <div style={{ display: "flex", flexDirection: msgData.memberId !== myId ? "row" : 'row-reverse', columnGap: "5px" }}>
                                        <SidebarChatAvatarContainer>
                                            <Avatar size={25} icon={<UserOutlined />} />
                                        </SidebarChatAvatarContainer>
                                        <Chat style={{ wordBreak: "break-word" }}>
                                            {msgData.message}
                                        </Chat>
                                    </div>
                                </div>
                            </SidebarChatRow>
                        )
                    })
                }
                <div ref={scrollRef} />
            </SidebarMemberBody>
        </Sidebar >

    );
}


export default SidebarTranscribe;