import { FaHandPaper, FaRegHandPaper } from "react-icons/fa";
import Cookies from 'js-cookie';

export function capitalizeWordsInArray(arr) {
  // Helper function to capitalize the first letter of a word
  function capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
  // Capitalize every word in each string of the array
  const capitalizedArray = arr.map((str) =>
    str
      .split(' ')
      .map((word) => capitalizeFirstLetter(word))
      .join(' ')
  );

  return capitalizedArray;
}

export function CapitalizeName(name) {
  if (name) {
    const arr = name?.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
  }
  return name
}

export const getCookieValue = (name) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`)
  const match = document.cookie.match(regex)
  if (match) {
    return match[2]
  }
}
export const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + " " + ampm;
  return strTime;
}
export const checkUserRaiseHand = (handRaisedIds, userId, type) => {
  const size = type === "mobile" ? 18 : 16
  if (handRaisedIds.includes(userId)) {
    return <FaHandPaper color="#ffff3a" size={size} />
  }
  else {
    return <FaRegHandPaper color={"white"} size={size} />
  }
}

export const handleSetCookie = (key, value) => {
  return Cookies.set(key, value, { expires: 7 });
};

export const handleGetCookie = (key) => {
  return Cookies.get(key);
};

export const statusColors = {
  raiseHand: "#ffff3a",
  brb: "#007bff",
  dnd: "#ff0000"
};



export const getTooltipTitle = (type, errors) => {
  const permissionKey = `${type}PermissionGranted`;
  const deviceKey = `${type}Device`;

  if (!errors[permissionKey] && errors[deviceKey]) {
    return `${type === "audio" ? "Audio" : "Video"} Permissions not granted`;
  }
  if (!errors[deviceKey]) {
    return `${type === "audio" ? "Mic" : "Camera"} not found on this device`;
  }
  return "";
};