import { PiWarningCircleFill } from "react-icons/pi";
import { useSelector } from "react-redux";

const WARNING_ICON_STYLE = { position: "absolute", top: "-5px", right: "-5px" };

export const WarningIcon = ({ color }) => (
  <PiWarningCircleFill style={{ ...WARNING_ICON_STYLE, color }} />
);





