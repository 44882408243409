import styled, { css } from "styled-components";
import { windowType } from "../../../../app/utils/enums";
import { Col, Row } from "antd";
// const PrimaryColor = "#1a5669";
// const SecondaryColor = "#ffff";
// const TertiaryColor = "#757d86";
const BlackColor = "#000";
const BlackColor2 = "#202124";

const MainContainer = styled.div`
  position: relative;
  overflow: hidden;
  background: ${BlackColor2};
  width: 100%;
  height: 100vh;
  display: flex;
  // justify-content: center;
  place-items: end;
  flex-direction: column;
`;
const MainDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100vh;
  width: calc(100vw - 20px);
  margin: 10px;
  background: #202124;
  // border:2px solid;
  border-radius: 16px;
`;

const BottomDiv = styled.div`
  position: fixed;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  display: flex;
  width: 98%;
  width: 95%;
  left: 50%;
  bottom: 7px;
  transform: translate(-50%, -5px);
  border-radius: 15px 15px 0px 0px;
  color: white;
  // background: ${BlackColor};
  padding: 10px 15px;
`;
const VideoDiv = styled.video`
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  background: white;
`;
const BottomMidDiv = styled.div`
  display: flex;
`;
const BottomLeftDiv = styled.div`
  @media (max-width: 320px) {
    display: none;
  }
  display: flex;
`;
const BottomRightDiv = styled.div`
  @media (max-width: 750px) {
    display: none;
  }
  display: flex;
  gap: 5px;
`;

const BottomRightMenuButton = styled.div`
  @media (max-width: 750px) {
    display: flex;
  }
  display: none;
`;

const SidebarInfoBodyButton = styled.button`
  width:100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: none;
  padding: 10px;
  gap: 10px;
  color: white;
  background: #707070;
`;

const Text = styled.div`
  font-weight: 200;
  color: white;
  word-spacing: 5px;
  font-size: 20px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;
const Time = styled.div`
  color: white;
  word-spacing: 0px;
  @media (max-width: 600px) {
    font-size: 10px;
  }
  @media (max-width: 400px) {
    font-size: 10px;
  }
`;

const VideoStreamRow = styled.div`
  ${(props) =>
    props.viewType == windowType.Mobile && props.colCount == 4
      ? css`
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: 25% 25% 25% 25%;
        `
      : props.colCount == 2 && props.viewType < 4
        ? css`
          grid-template-columns: repeat(2, 1fr);
        `
        : props.colCount == 2 && props.viewType == windowType.Mobile
          ? css`
          grid-template-columns: repeat(${props.colCount / 2}, 1fr);
        `
          : props.colCount == 3 && props.viewType == windowType.Mobile
            ? css`
          grid-template-columns: repeat(1, 1fr); 
          grid-template-rows: 33% 33% 33%;
        `
            : props.colCount <= 4 && props.colCount > 2
              ? css`
          grid-template-columns: repeat(${Math.ceil(props.colCount / 2)}, 1fr);
          grid-template-rows: 50% 50%;
        `
              : props.colCount == 1
                ? css`
          grid-template-columns: repeat(${props.colCount}, 1fr);
        `
                : props.viewType == windowType.Desktop
                  ? css`
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: 50% 50%;
        `
                  : props.viewType == windowType.Tablet
                    ? css`
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: 50% 50%;
        `
                    : props.viewType == windowType.SmallTablet
                      ? css`
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 50% 50%;
        `
                      : ""}
  display:grid;
  height: 100%;
  width: 100%;
`;
const VideoStreamCol = styled.div`
  height: 100%;
  width: 100%;
`;
const VideoStreamTag = styled.video`
  display: flex;
  // align-items:center;
  // justify-content:center;
  ${(props) =>
    (props.screenShare || (props.currentDeviceIndex && props.userId === props.myId))
      ? css`
         object-fit: contain;
        `
      : css`
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        object-fit: contain;
        `
  };
 
  border-radius: 8px;
  border: 4px solid #202124;
  background-color: black;
  width: 100%;
  height: 100%;
  
  position:absolute;
`;
const NotVideoStreamTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 4px solid #202124;
  background-color: gray;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const NameTagSideBarLayout = styled.div`
  color: white;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  position: absolute;
  top: 1px;
  left: 2%;
  font-size: 12px;
  `;
const NameEmailTag = styled.div`
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  ${(props) =>
    (props.colCount === 1)
      ? css`
      width: calc(100vw - 3.5%);
      height: 100vh;
      `
      :
      props.colCount === 2 ?
        css`
      width: calc(100vw - 52.4%);
      `
        :
        props.colCount > 2 && props.colCount <= 4 ?
          css`
          width: calc(100vw - 52.4%);
          height: 50vh;
          ` :
          props.colCount > 4 && props.colCount <= 8 ?
            css`
            width: calc(100vw / 4);
            height: 50vh;
            margin-left: 30px;
        `
            :
            css``
  };
  ${(props) =>
    (props.video) ?
      css`
    margin-left:13px;
    `
      :
      ``
  }
  @media (max-width: 480px) {
    width: calc(100vw - 50px);
    ${(props) =>
    (props.colCount === 1)
      ? css`
          height: 100vh;
          `
      :
      props.colCount === 2 ?
        css`
          height: 50vh;
          `
        :
        props.colCount === 3 ?
          css`
          height: 33vh;
          `
          :
          props.colCount >= 4 ?
            css`
          height: 25vh;
          `
            :
            css``
  };
  }
`;
const EmailTag = styled.div`
  font-size: small;
  top: 7.5%;
  position:absolute;
  color: rgb(255, 255, 255);  
  background-color: rgba(0,0,0,0.4);
  color: white;
  border: none;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  ${(props) =>
    props.colCount > 2 && props.colCount <= 8 ?
      css`
          top: 13.5% ;
          `
      :
      (props.video && props.colCount > 2 && props.colCount <= 8)
        ? css`
        top:11.5%;
        `
        :
        css``
  };
  @media (min-width: 481px and max-width: 800px) {
    top:9%;
  }
  @media (max-width: 480px) {
    top:7%;
    ${(props) =>
    (props.colCount === 1)
      ? css`
            top:7%;
            `
      :
      props.colCount === 2 ?
        css`
          top:10.5%;
            `
        :
        props.colCount === 3 ?
          css`
          top: 16%;
            `
          :
          props.colCount >= 4 ?
            css`
          top: 19%;           
           `
            :
            css``
  };
  }
`;
const NameTag = styled.button`
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: medium;
  font-weight: 400;
  display: none;
  background-color: rgba(0,0,0,0.4);
  color: white;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  cursor: pointer;
  outline: inherit;
  ${(props) =>
    (props.colCount > 2 && props.colCount <= 8) ?
      css`
          top: 5%;
        ` :
      css`
      top:3%;
      `
  };
  @media (max-width: 480px) {

  }
`;
const handRaiseCount = styled.div`
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: medium;
  font-weight: 400;
  display: none;
  background-color: rgba(0,0,0,0.4);
  color: white;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  cursor: pointer;
  outline: inherit;
  ${(props) =>
    (props.colCount > 2 && props.colCount <= 8) ?
      css`
          top: 5%;
        ` :
      css`
      top:3%;
      `
  };
  @media (max-width: 480px) {

  }
`;

const SidebarMemberRowLeftMiddle = styled.div`
  display: flex;
  gap: 20px;
`;

const VideoStreamMain = styled.div`
  // width:100%;

  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  //  justify-content: center;
`;

const SidebarView = styled.div`
  transform: translateX(110%);
  // transition: transform 0.1s;
  height: 85%;
  width: 450px;
  display: flex;
  right: 15px;
  top: 20px;
  position: absolute;
  padding: 10px;
  @media (max-width: 500px) {
    width: 90%;
  }
`;

const MobileSidebarView = styled.div`
  transform: translateX(110%);
  transition: transform 0.3s;
  display: none;
  right: 15px;
  bottom: 70px;
  position: absolute;
  padding: 10px;
  @media (max-width: 750px) {
    width: 55%;
    display: flex;
  }
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0px 10px 0px 10px;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  border-radius: 16px;
  width: 100%;
`;

const SidebarHeaderRow = styled(Row)`
  gap: 10px;
`;

const SidebarMemberRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // border-radius:10px;
  // border:2px solid red;
  margin: 10px 12px 10px 12px;
`;

const ChatInput = styled.input`
  padding-left: 10px;
  border-radius: 10px;
  border: none;
  height: 40px;
  width: 100%;
  background: #707070;
  :focus,
  input:focus {
    outline: none;
    color: white;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 0.5; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white;
    opacity: 0.5;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;
    opacity: 0.5;
  }
`;

const ChatForm = styled.form`
  width: 100%;
  display: flex;
`;
const ChatButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  background: none;
`;

const SidebarHeaderInput = styled.input`
  padding-left: 10px;
  :focus,
  input:focus {
    outline: none;
    color: white;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 0.5; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white;
    opacity: 0.5;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;
    opacity: 0.5;
  }
  border: none;
  width: 100%;
  margin: 10px;
  margin-top: 15px;
  height: 50px;
  border-radius: 10px;
  background: #707070;
`;

const SidebarChatRowInput = styled.div`
  margin: 10px;
  position: absolute;
  bottom: 0px;
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  align-items: center;
  padding: 5px;
  right: 0.2px;
  height: 50px;
`;
const Chat = styled.div`
  word-break:break-all;
  font-weight: 200;
  color: white;
  line-height: 1.2;
  max-width: 80%;
  border-radius: 5px;
  background: #707070;
  display: flex;
  align-items: center;
  padding: 5px;
`;
const SidebarChatRow = styled.div`
  ${(props) =>
    props.type == "mine"
      ? css`
          flex-direction: row-reverse;
        `
      : ""}
  gap:5px;
  width: inherit;
  margin: 10px 10px 0px 10px;
  height: auto;
  @media (max-width: 600px) {
    height: auto;
  }
  display: flex;
  background: none;
`;
const SidebarShareRow = styled.button`
  cursor: pointer;
  gap: 10px;
  padding-left: 10px;
  border: none;
  width: 100%;
  margin: 0px 5px 0px 5px;
  height: 40px;
  @media (max-width: 600px) {
    height: 40px;
  }
  border-radius: 10px;
  background: #707070;
  display: flex;
  align-items: center;
`;

const SidebarHeaderCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #707070;
  position: relative;
  ${(props) =>
    props.sidebarlayout
      ? css`
          height: 100px;
        `
      : props.sidebarmembers
        ? css`
          height: 40px;
        `
        : css`
          height: 100px;
        `}
`;

const SidebarMemberDesignation = styled.div`
  font-weight: 400;
  color: white;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: white;
  @media (max-width: 600px) {
    font-size: 13px;
    word-spacing: 0px;
  }
`;
const SidebarMemberName = styled.div`
  font-weight: 200;
  color: white;
  font-size: 15px;
  display: flex;
  align-items: center;
  color: white;
  @media (max-width: 600px) {
    font-size: 13px;
    word-spacing: 0px;
  }
`;

const HeaderColText = styled.div`
  font-weight: 200;
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: white;
  margin: 0px;
  @media (max-width: 600px) {
    font-size: 15px;
  }
  @media(min-width: 250px) and (max-width: 399px)
  {
    font-size: 11px;
  }
  @media (max-width: 200px) {
    font-size: 8px;
  }
`;

const SidebarHeading = styled.div`
  font-weight: 400;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: white;
  @media (max-width: 600px) {
    font-size: 20px;
    word-spacing: 0px;
  }
`;
const SidebarInfoBodyHeader = styled.div`
  margin-bottom: 10px;
  font-weight: 300;
  color: white;
  font-size: 20px;
  @media (max-width: 600px) {
    font-size: 20px;
    word-spacing: 0px;
  }
`;

const SidebarInfoBody = styled.div`
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ::-moz-scrollbar {
    display: none;
  }
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin: 0px 10px 0px 10px;
`;
const SidebarHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  padding-top: 6px;
`;

const SidebarMemberRowRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;
const SidebarMemberRowMiddle = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
`;

const SidebarMemberRowLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const SidebarHeaderColBody = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const SidebarHeaderButton = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 10px;
  background: #707070;
  cursor: pointer;
  @media (max-width: 600px) {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

const SidebarHR = styled.hr`
  opacity: 10%;
  margin: 20px 10px 20px 10px;
`;

const SidebarShareIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: white;
  height: 25px;
  width: 25px;
  cursor:pointer;
  @media (max-width: 600px) {
    height: 30px;
    width: 30px;
  }
`;
const SidebarMemberBody = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
  ::-moz-scrollbar {
    display: none;
  }
  overflow-y: scroll;
`;

const SidebarBody = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
  ::-moz-scrollbar {
    display: none;
  }
  margin: 12px;
  margin-top: 0px;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;
const SidebarBodyCol = styled.div`
  border-radius: 10px;
  background: #707070;
  height: 100%;
  width: 100%;
`;

const SidebarMemberRowButton = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
 
  @media (max-width: 600px) {
    width: 20px;
    height: 20px;
  }
`;
const CircleDiv = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  height: 180px;
  width: 180px;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: white;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  @media (max-width: 500px) {
    width: 80px;
    height: 80px;
  }
  @media (min-width: 501px) and (max-width: 1000px) {
    width: 150px;
    height: 150px;
  }
  ${(props) =>
    props.sidebarlayout == "sidebarlayout"
      ? css`
          margin: 20px 0px 20px 0px;
          width: 100px !important;
          height: 100px !important;
          @media (max-width: 500px) {
            width: 70px !important;
            height: 70px !important;
          }
        `
      : ""}
`;
const VideoText = styled.div`
  font-size: 4vw;
  text-align: center;
`;
const PinTag = styled.div`
${(props) =>
    props.sidebarlayout == "sidebarlayout"
      ? css`
        padding: 1px;
        border-radius: 100px;
      `
      : css`
        padding: 10px;
        border-radius: 50px;
    `}
  justify-content: center;
  align-items: center;
  position: absolute;
  background: #00000094;
  
  opacity: 0;
`;

const VideoStreamParentdiv = styled.div`
  height: 100%;
  position: relative;
`;
const SidebarChatAvatarContainer = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
`;
const SideBarCopyRightTag = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  columnGap: 8px;
  bottom: 10px;
  color: white;
  font-size: 10px;
  position: absolute;
`;

const Styles = {
  SideBarCopyRightTag,
  SidebarChatAvatarContainer,
  PinTag,
  CircleDiv,
  VideoText,
  ChatInput,
  ChatButton,
  SidebarChatRow,
  SidebarShareRow,
  SidebarInfoBodyButton,
  SidebarInfoBodyHeader,
  SidebarInfoBody,
  HeaderColText,
  SidebarMemberDesignation,
  SidebarMemberName,
  SidebarMemberRow,
  SidebarMemberBody,
  SidebarHeaderInput,
  SidebarBodyCol,
  SidebarBody,
  SidebarHeaderCol,
  SidebarHR,
  SidebarHeaderButton,
  SidebarHeader,
  SidebarHeading,
  SidebarHeaderRow,
  BottomRightMenuButton,
  VideoStreamMain,
  VideoStreamRow,
  VideoStreamCol,
  VideoStreamTag,
  MainContainer,
  MainDiv,
  BottomDiv,
  BottomLeftDiv,
  BottomMidDiv,
  BottomRightDiv,
  Text,
  Sidebar,
  SidebarView,
  SidebarMemberRowRight,
  SidebarMemberRowMiddle,
  SidebarMemberRowLeft,
  SidebarMemberRowButton,
  SidebarMemberRowLeftMiddle,
  SidebarHeaderColBody,
  SidebarShareIcon,
  Chat,
  SidebarChatRowInput,
  ChatForm,
  MobileSidebarView,
  NotVideoStreamTag,
  VideoStreamParentdiv,
  NameEmailTag,
  EmailTag,
  NameTag,
  handRaiseCount,
  NameTagSideBarLayout,
  Time
};

export default Styles;
