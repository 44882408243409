
const MyStreams = (function () {
  let instance;
  let screenShare;
  let videoDevices = [];
  return {
    getInstance: async function () {
      try {
        let audioPermissionGranted = false;
        let videoPermissionGranted = false;
        let audioDevice = true;
        let videoDevice = true;
        // Test audio permission
        try {
          await navigator.mediaDevices.getUserMedia({ audio: true });
          audioPermissionGranted = true
        } catch (audioError) {
          if (audioError.name === "NotAllowedError" || audioError.name === "PermissionDeniedError") {
            console.warn("Permission denied for audio devices.");
            audioPermissionGranted = false;
          }
          if (audioError.name === "NotFoundError") {
            audioDevice = false
          }
          // else {
          //   throw audioError; // Handle unexpected errors for audio
          // }
        }

        // Test video permission
        try {
          await navigator.mediaDevices.getUserMedia({ video: true });
          videoPermissionGranted = true
        } catch (videoError) {
          if (videoError.name === "NotAllowedError" || videoError.name === "PermissionDeniedError") {
            console.warn("Permission denied for video devices.");
            videoPermissionGranted = false;
          }
          if (videoError.name === "NotFoundError") {
            videoDevice = false
          }
          // else {
          //   throw videoError; // Handle unexpected errors for video
          // }
        }

        // if (!audioPermissionGranted || !videoPermissionGranted) {
        //   return { error: "PermissionDenied", audio: audioPermissionGranted, video: videoPermissionGranted };
        // }

        // Enumerate devices after checking permissions
        const deviceList = await navigator.mediaDevices.enumerateDevices();
        const audioDevices = deviceList.filter(device => device.kind === "audioinput");
        videoDevices = deviceList.filter(device => device.kind === "videoinput");

        // Check if no devices are available
        // if (audioDevices.length === 0 && videoDevices.length === 0) {
        //   console.log("No audio or video devices available.");
        //   NoDevices = true
        //   // return { error: "NoDevices" };
        // }

        if (instance) return instance;

        // Get default device IDs
        const defaultVideoDeviceId = videoDevices[0]?.deviceId;
        const defaultAudioDeviceId = audioDevices[0]?.deviceId;

        // console.log(defaultVideoDeviceId, "defaultVideoDeviceId");
        // console.log(defaultAudioDeviceId, "defaultAudioDeviceId");

        let mediaStream;

        // Handle media stream creation based on device availability
        if (defaultAudioDeviceId && defaultVideoDeviceId) {
          mediaStream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: defaultVideoDeviceId },
            audio: true,
          });
        } else if (defaultAudioDeviceId) {
          mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        } else if (defaultVideoDeviceId) {
          mediaStream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: defaultVideoDeviceId },
          });
        }

        // console.log("STREAM:", mediaStream);

        // Create the instance object
        const mediaStreamLength = mediaStream?.id;
        instance = {
          audioStream: mediaStreamLength && new MediaStream(mediaStream.getAudioTracks()),
          videoStream: mediaStreamLength && new MediaStream(mediaStream.getVideoTracks()),
          isAudio: Boolean(defaultAudioDeviceId),
          isCamera: Boolean(defaultVideoDeviceId),
          deviceId: defaultVideoDeviceId,
          error: {
            audioPermissionGranted,
            videoPermissionGranted,
            audioDevice,
            videoDevice
          }
        };
        return instance;
      } catch (error) {
        console.log("An unexpected error occurred:", error);
        return {
          error: "Unknown Error"
        };
      }
    },
    getScreenShare: async function () {
      if (screenShare) return { screenShare };
      const constraints = { video: { cursor: "always" }, audio: false };
      screenShare = await navigator.mediaDevices.getDisplayMedia(constraints);
      return { screenShare };
    },
    deleteScreenShare: async function () {
      await screenShare.getTracks().forEach((track) => {
        track.stop();
      });
      screenShare = null;
    },
    switchCamera: async function (index) {
      if (!instance || !videoDevices[index]) return instance;

      instance.videoStream.getTracks().forEach(t => {
        t.stop();
        instance.videoStream.removeTrack(t);
      });

      const deviceId = videoDevices[index].deviceId;
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId },
        audio: true,
      });

      const videoTracks = stream.getVideoTracks();

      instance.videoStream = new MediaStream(videoTracks);
      instance.deviceId = ""

      // Return the updated instance after switching camera
      return { ...instance };
    },
  };
})();

export default MyStreams;
